import React from 'react';
import { BuscaNfeContextProvider } from './BuscaNfeContext';
import { BuscaNfeContent } from './BuscaNfeContent';
import { BuscaNfeProps } from './protocols';

export const BuscaNfe: any = ({
  codLoja,
  fornecedorOption,
  numNF,
  quantityItems = 0,
  setNfData,
  reset,
  disabled = false,
}: BuscaNfeProps) => {
  return (
    <BuscaNfeContextProvider
      codLoja={codLoja}
      fornecedorOption={fornecedorOption}
      numNF={numNF}
      quantityItems={quantityItems}
      setNfData={setNfData}
      reset={reset}
      disabled={disabled}
    >
      <BuscaNfeContent />
    </BuscaNfeContextProvider>
  );
};
