import { formatCurrencyAsText } from '~/utils/functions';

export const createToleraConfirmation = (items: any[]): string => {
  const rows = items
    .map(
      (item) => `
    <tr>
      <td style="border-bottom: 1px solid #ddd; padding: 8px;">${
        item.num_item
      }</td>
      <td style="border-bottom: 1px solid #ddd; padding: 8px;">${
        item.des_produto
      }</td>
      <td style="border-bottom: 1px solid #ddd; padding: 8px;">${
        item.cod_produto
      }</td>
      <td style="border-bottom: 1px solid #ddd; padding: 8px;">${formatCurrencyAsText(
        item.variacao_produto,
      )}</td>
    </tr>
  `,
    )
    .join('');

  return `
    <style>
      .swal-wide {
        width: 45% !important;
        max-width: 45% !important;
      }
    </style>
    <div>
      <table style="width:100%; text-align: left; border-collapse: collapse;">
        <thead>
          <tr>
            <th style="border-bottom: 1px solid #ddd; padding: 8px;">Item</th>
            <th style="border-bottom: 1px solid #ddd; padding: 8px;">Descrição</th>
            <th style="border-bottom: 1px solid #ddd; padding: 8px;">PLU</th>
            <th style="border-bottom: 1px solid #ddd; padding: 8px;">% Variação</th>
          </tr>
        </thead>
        <tbody>
          ${rows}
        </tbody>
      </table>
      <strong style="margin-top: 15px; display: block;">Deseja continuar mesmo assim?</strong>
    </div>
  `;
};
