import React, { ReactNode } from 'react';

import { Button } from '../styles';

interface ButtonProps {
  onClick(): any;
  style?: any;
  children?: ReactNode;
  disabled?: boolean;
}

export const CancelButton: React.FC<ButtonProps> = ({
  onClick,
  style,
  children,
  disabled = false,
}) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      style={style}
      className="cancel"
      data-bs-toggle="collapse"
      data-bs-target="#collapseTwo"
      aria-expanded="false"
      aria-controls="collapseTwo"
      disabled={disabled}
    >
      {children || 'Cancelar'}
    </Button>
  );
};

export default CancelButton;
