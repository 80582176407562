import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createToleraConfirmation } from './createToleraConfirmation';

const MySwal = withReactContent(Swal);

export const showToleraConfirmationDialog = async (
  items: any[],
): Promise<boolean> => {
  const { isConfirmed } = await MySwal.fire({
    title:
      'Custo de algum(ns) produto(s) excede(m) o percentual de tolerância ou bloqueio.',
    showCancelButton: true,
    showCloseButton: false,
    showConfirmButton: true,
    confirmButtonColor: '#3F48CC',
    cancelButtonColor: '#ED1C24',
    showDenyButton: false,
    confirmButtonText: 'SIM',
    cancelButtonText: 'NÃO',
    customClass: { popup: 'swal-wide' },
    html: createToleraConfirmation(items),
  });

  return isConfirmed;
};
