export const toleraValor = (
  variacaoProduto: number,
  perTolerancia: any,
): boolean => {
  if (variacaoProduto > 0) {
    if (variacaoProduto > perTolerancia) {
      return false;
    }
    return true;
  }
  return true;
};
