import React, { useState, useEffect, useCallback } from 'react';
import { Container } from './styles';

import api from '~/services/api';
import InputSelect from '../Inputs/InputSelect';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface IOperadores {
  cod_operador: number;
  des_operador: string;
  cod_loja: number;
}

interface OperadoresProps {
  value: Options | undefined | null;
  loja: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const BuscaOperadoresLoja: React.FC<OperadoresProps> = (props) => {
  const {
    loja,
    onChange,
    value,
    isRequired,
    isDisabled,
    setInvalid,
    iniInicializado,
    menuPosition,
    menuPlacement,
    minMenuHeight,
  } = props;

  const [operadores, setOperadores] = useState<Options[]>([]);
  const [operadoresAtivos, setOperadoresAtivos] = useState<Options[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.post(`/busca-operador`, { lojas: loja });

      if (data.success && data.data) {
        const options = data.data.map((operador: IOperadores) => ({
          label: operador.des_operador,
          value: operador.cod_operador,
        }));
        setOperadores(options);

        const optAtivos = data.data.flatMap((op: any) => {
          if (op.flg_inativo !== true) {
            return [
              {
                label: op.des_operador,
                value: op.cod_operador,
              },
            ];
          }
          return [];
        });

        setOperadoresAtivos(optAtivos);
      }
    })();
  }, [loja]);

  const handleChangeOperador = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
    },
    [onChange],
  );

  return (
    <Container>
      <div className="col-sm-12">
        <InputSelect
          placeholder={
            isDisabled === true ? 'Informe a Loja Primeiro' : 'Selecione'
          }
          options={operadoresAtivos}
          label="Operador"
          noOptionsMessage="Nenhum registro encontrado"
          value={
            operadores.find((operador: Options) => {
              return operador.value === value?.value;
            }) ?? null
          }
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          iniInicializado={iniInicializado}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangeOperador(newValue, isInvalid);
          }}
        />
      </div>
    </Container>
  );
};

export default BuscaOperadoresLoja;
