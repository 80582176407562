import React, { useCallback, useEffect, useState } from 'react';
import api from '~/services/api';

import InputSelect from '../Inputs/InputSelect';
import { Container } from './styles';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface IPdv {
  num_pdv: number;
  cod_loja: number;
}

interface PdvProps {
  value: Options | undefined | null;
  loja: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  label?: string | undefined;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  menuPosition?: any;
  menuPlacement?: any;
  minMenuHeight?: number;
}

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 */
const BuscaPdvLoja: React.FC<PdvProps> = (props) => {
  const {
    loja,
    onChange,
    value,
    label = 'Caixa',
    isRequired,
    isDisabled,
    setInvalid,
    iniInicializado,
    menuPosition,
    menuPlacement,
    minMenuHeight,
  } = props;

  const [pdvs, setPdvs] = useState<Options[]>([]);
  const [pdvsAtivos, setPdvsAtivos] = useState<Options[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.post(`/busca-pdvs`, { lojas: loja });
      if (data.success && data.data) {
        const options = data.data.map((pdv: IPdv) => ({
          label: pdv.num_pdv,
          value: pdv.num_pdv,
        }));

        const optAtivos = data.data.flatMap((pdv: any) => {
          if (pdv.flg_inativo !== true) {
            return [
              {
                label: pdv.num_pdv,
                value: pdv.num_pdv,
              },
            ];
          }
          return [];
        });

        setPdvs(options);
        setPdvsAtivos(optAtivos);
      }
    })();
  }, [loja]);

  const handleChangePdv = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
    },
    [onChange],
  );

  return (
    <Container>
      <div className="col-sm-12">
        <InputSelect
          placeholder={
            isDisabled === true ? 'Informe a Loja Primeiro' : 'Selecione'
          }
          options={pdvsAtivos}
          label={label}
          noOptionsMessage="Nenhum registro encontrado"
          value={
            pdvs.find((pdv: Options) => {
              return pdv.value === value?.value;
            }) ?? null
          }
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          menuPlacement={menuPlacement}
          minMenuHeight={minMenuHeight}
          iniInicializado={iniInicializado}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangePdv(newValue, isInvalid);
          }}
        />
      </div>
    </Container>
  );
};

export default BuscaPdvLoja;
