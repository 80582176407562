import styled from 'styled-components';

export const Button = styled.button`
  padding: 3px 12px;
  height: 40px;
  width: 150px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.confirm {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }

  &.cancel {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #dc3545;
    &:disabled {
      background-color: #e3bec1;
      cursor: not-allowed;
      &:hover {
        background-color: #e3bec1;
        opacity: 1;
      }
    }
  }

  &.clear {
    @media (max-width: 800px) {
      width: 75px;
    }
    background-color: #ffc107;
    &:disabled {
      background-color: #f1e1b0;
      cursor: not-allowed;
      &:hover {
        background-color: #f1e1b0;
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    margin-left: 8px;
  }
`;
