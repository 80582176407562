import styled from 'styled-components';
import { Button as btn } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;

  .async-select-with-callback {
    z-index: 99 !important;
  }

  .single-select .react-select__menu {
    z-index: 9999999999 !important;
  }
`;
