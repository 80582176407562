import React, { ReactNode } from 'react';

import { Button } from '../styles';

interface ButtonProps {
  onClick(): any;
  style?: React.CSSProperties;
  children?: ReactNode;
  disabled?: boolean;
  title?: string;
}

export const CustomButton: React.FC<ButtonProps> = ({
  onClick,
  style,
  children,
  disabled = false,
  title = '',
}) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      style={style}
      disabled={disabled}
      title={title}
    >
      {children || 'Confirmar'}
    </Button>
  );
};

export default CustomButton;
