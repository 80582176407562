import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  .rowHight {
    min-height: 700px !important;
    background: red !important;
  }
`;

export const ButtonNfe = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 160px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.success {
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  color: #706f6f;
  font-family: 'Jost', sans-serif !important;
  font-weight: 500;
  font-size: 16px;

  .btn-group {
    cursor: pointer;
    border-radius: 0px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
    &:hover {
      background-color: #8850bf;
      color: #fff !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #fff !important;

    &:hover {
      background-color: #57069e;
      color: #fff;
    }
  }
`;

export const GroupItem = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  button {
    align-items: center;
    height: 42px;
    width: 50%;
    border-radius: 50%;
  }
  .label {
    color: #6b778c;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  height: 42px;
  justify-content: flex-end;
  gap: 10px;
  button {
    display: flex;
    gap: 10px;
    min-width: 100px;
    height: 42px;
    align-items: center;
    justify-content: center;
  }
  button: {
    box-shadow: none;
  }

  button.success {
    background-color: #28a745 !important;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }
`;

export const ContainerTable = styled.div`
  margin: 20px 0;
  display: flex;
`;

export const ContainerAcoes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: transparent;
    border: none;
  }
`;
