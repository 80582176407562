import styled, { css } from 'styled-components';
import { ButtonPropsNovo } from './types';

const getButtonColor = (variant: string) => {
  switch (variant) {
    case 'confirm':
      return '#28a745';
    case 'cancel':
      return '#CF9D08';
    case 'clear':
      return '#007BFF';
    default:
      return '#8850BF';
  }
};

export const StyledButtonNovo = styled.button<ButtonPropsNovo>`
  height: ${({ height }) => height || '2.5rem'};
  width: ${({ width }) => width || '9.375rem'};
  border-radius: 0.25rem;
  padding: 0.1875rem 0.75rem;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 1.3125rem;
  animation: all 0.25s ease-in;
  user-select: none;
  opacity: 1;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  ${(props) =>
    props.variant &&
    css`
      background-color: ${getButtonColor(props.variant)};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `};
`;

export const IconWrapper = styled.span<{ position: 'left' | 'right' }>`
  margin-right: ${(props) => (props.position === 'left' ? '0.5rem' : 0)};
  margin-left: ${(props) => (props.position === 'right' ? '0.5rem' : 0)};
`;
